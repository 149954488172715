import { createContext, useContext } from "react";

/* Models */
import yaris from "../assets/molelos/yaris.jpg";
import corolla from "../assets/molelos/corolla.jpeg";
import corollacross from "../assets/molelos/corollacross.jpeg";
import corollacrosshibrido from "../assets/molelos/corollacrosshibrido.webp";
import hilux4x2 from "../assets/molelos/hilux4x2.jpg";
import hilux4x4 from "../assets/molelos/hilux4x4.jpg";

export const ConsContext = createContext();

export const useCons = () => {
  const context = useContext(ConsContext);
  if (!context) throw new Error("useCons must be used within a ConsContext");
  return context;
};

export const ConsContextProvider = ({ children }) => {
  const prices = {
    yaris: {
      cuota: "232.212",
      valor: "23.571.000"
    },
    corolla: {
      cuota: "255.097",
      valor: "28.824.000"
    },
    corollacrosshibrido: {
      cuota: "553.555",
      valor: "40.821.000"
    },
    corollacross: {
      cuota: "331.814",
      valor: "37.456.000"
    },
    hilux4x2: {
      cuota: "374.371",
      valor: "38.001.000"
    },
    hilux4x4: {
      cuota: "438.408",
      valor: "44.507.000"
    }
  };

  const modelos = [
    {
      id: 227,
      nombre: "Yaris",
      modelo: "Toyota Yaris",
      cuota: prices.yaris.cuota,
      plan: `Financiá hasta el 70%`,
      text1: `Valor de la unidad $${prices.yaris.valor}`,
      text2: "Plan 70/30",
      text3: "Hasta en 96 Meses",
      text4: "Podés cancelar y adelantar cuotas",
      img: yaris,
      dataButton: "Plan Toyota Yaris"
    },
    {
      id: 2,
      nombre: "Corolla Sedan",
      modelo: "Toyota Corolla Sedan",
      cuota: prices.corolla.cuota,
      plan: `Financiá el 60%`,
      text1: `Valor de la unidad $${prices.corolla.valor}`,
      text2: "Plan 60/40",
      text3: "Hasta en 96 Meses",
      text4: "Podés cancelar y adelantar cuotas",
      img: corolla,
      dataButton: "Plan Toyota Corolla Sedan"
    },
    {
      id: 245,
      nombre: "Corolla Cross XLI",
      modelo: "Toyota Corolla Cross XLI",
      cuota: prices.corollacross.cuota,
      plan: `Financiá el 60%`,
      text1: `Valor de la unidad $${prices.corollacross.valor}`,
      text2: "Plan 60/40",
      text3: "Hasta en 96 Meses",
      text4: "Podés cancelar y adelantar cuotas",
      img: corollacross,
      dataButton: "Plan Toyota Corolla Cross XLI"
    },
    {
      id: 245,
      nombre: "Corolla Cross Híbrido",
      modelo: "Toyota Corolla Cross Híbrido",
      cuota: prices.corollacrosshibrido.cuota,
      plan: `Financiá el 100%`,
      text1: `Valor de la unidad $${prices.corollacrosshibrido.valor}`,
      text2: "Plan 100%",
      text3: "Hasta en 96 Meses",
      text4: "Podés cancelar y adelantar cuotas",
      img: corollacrosshibrido,
      dataButton: "Plan Toyota Corolla Cross Híbrido"
    },
    {
      id: 1,
      nombre: "Hilux 4X2",
      modelo: "Toyota Hilux 4X2",
      cuota: prices.hilux4x2.cuota,
      plan: `Financiá el 70%`,
       text1: `Valor de la unidad $${prices.hilux4x2.valor}`,
      text2: "Plan 70/30",
      text3: "Hasta en 96 Meses",
      text4: "Podés cancelar y adelantar cuotas",
      img: hilux4x2,
      dataButton: "Plan Toyota Hilux 4X2"
    },
    {
      id: 1,
      nombre: "Hilux 4X4",
      modelo: "Toyota Hilux 4X4",
      cuota: prices.hilux4x4.cuota,
      plan: `Financiá el 70%`,
      text1: `Valor de la unidad $${prices.hilux4x4.valor}`,
      text2: "Plan 70/30",
      text3: "Hasta en 96 Meses",
      text4: "Podés cancelar y adelantar cuotas",
      img: hilux4x4,
      dataButton: "Plan Toyota Hilux 4X4"
    }
  ];

  const themeOptions = {
    acentcolor: "#eb0a1e",
    dealer: "Kansai",
    carBrand: "Toyota",
    brand: "logo.png",
    logo: "logoCons.png",
    urlHook: "https://hook.us1.make.com/copkn4amdufai1say3t1efedqqlu4pcg"
  };

  const modelsOptions = modelos.map((model) => {
    return {
      value: model.dataButton,
      text: `${model.dataButton} (Cuota $${model.cuota})`,
      disabled: model.disabled
    };
  });

  console.log(modelsOptions);

  return (
    <ConsContext.Provider
      value={{
        prices,
        modelos,
        modelsOptions,
        themeOptions
      }}
    >
      {children}
    </ConsContext.Provider>
  );
};
